import PageGeneral from 'components/pages/general';
import { fetchContent } from 'lib/contentful/api';
import { CORE_SECTIONS_FRAGMENT } from 'components/sections';
import { SEO_FRAGMENT } from 'components/SEO';
import addGlobalModule from 'lib/contentful/addGlobalModule';
import addSections from 'lib/contentful/addSections';
import { asyncPipe } from 'lib/utils/helpers';
import getLayoutItems from 'lib/contentful/getLayoutItems';
import withStoreLocale from 'lib/storeLocales/staticProps/withStoreLocale';
import getCurrency from 'lib/storeLocales/getCurrency';

const getGeneralPageQuery = ({ slug, locale = 'en', preview }) => `
  {
    pageGeneralCollection(limit: 1, where: { slug: "${slug}" }, locale: "${locale}", preview: ${preview}) {
      items {
        slug
        headerTransparent
        ${CORE_SECTIONS_FRAGMENT}
        ${SEO_FRAGMENT}
        globalModule {
          sys {
            id
          }
          name
        }
      }
    }
  }
`;

const pluckItem = page => page?.pageGeneralCollection?.items[0];

const pageFetcher = (locale, preview) => async slug => {
  const generalPageQuery = getGeneralPageQuery({ slug, locale, preview });
  const pageCore = pluckItem(await fetchContent(generalPageQuery, preview));
  return pageCore;
};

export const getStaticProps = withStoreLocale(
  async ({ preview = false, previewData = null, locale, store }) => {
    const slug = 'not-found';

    const currency = getCurrency(store);

    const getPage = asyncPipe(
      pageFetcher(locale, preview),
      addSections(locale, preview),
      addGlobalModule(locale, preview, currency)
    );

    const page = await getPage(slug);

    const { header, footer, cart, storeLocaleSelector, uspBanner } = await getLayoutItems(
      locale,
      preview,
      store
    );

    return {
      props: {
        previewData,
        page,
        header,
        footer,
        cart,
        locale,
        currency,
        store,
        storeLocaleSelector,
        uspBanner,
      },
      revalidate: 1,
    };
  }
);

export default PageGeneral;
